import React from 'react'

function Divider() {
  return (
    <div
     className='w-full h-[1px] bg-gray-300 my-2'
    ></div>
  )
}

export default Divider